import React, { useState, useEffect } from 'react';
import { Local, Secure } from 'grommet-icons';
import { PhoneVertical } from 'atoms/ui/Icons';
import PinInput from 'react-pin-input';
import { userHasValidPhoneNumber } from 'services/utils';
import { sendVerifyCode, verifyCode, checkPassword } from './api';
import {
  MfaWrapper,
  Heading,
  IconWrapper,
  OK,
  InputWraper,
  Input,
  Wrapper,
} from './styles';

/**
 * telephone entry component, sets parent step validity through onChange
 * @param {*} param0
 */
export const MfaTelEntry = ({ mFA, onChange }) => {
  const [valid, setValid] = useState(false);
  const handleChange = ({ target: { value } }) => {
    const { error } = userHasValidPhoneNumber(value);
    if (!error) {
      setValid(true);
      onChange({ stepValid: true });
    } else if (error && valid) {
      setValid(false);
      onChange({ stepValid: false });
    }
    onChange({ mFA: value });
  };
  return (
    <MfaWrapper>
      <PhoneVertical style={{ width: '3rem', height: '3rem', fill: 'black' }} />
      <Heading>Let's setup your phone.</Heading>
      <p>What phone number do you want to use?</p>
      <InputWraper>
        <Input
          icons
          value={mFA}
          onChange={handleChange}
          data-cy="mfa_tel_entry"
        />
        <IconWrapper style={{ left: 0 }}>
          <Local />
        </IconWrapper>
        <IconWrapper style={{ right: 0 }}>
          <OK valid={valid} />
        </IconWrapper>
      </InputWraper>
    </MfaWrapper>
  );
};

export const MfaPWDInput = ({ onChange, password }) => {
  return (
    <MfaWrapper>
      <Secure style={{ width: '3rem', height: '3rem', fill: 'black' }} />
      <Heading>Please verify your identity.</Heading>
      <p>Provide your password to continue setup.</p>
      <Input
        value={password}
        type="password"
        onChange={({ target }) =>
          onChange({ password: target.value, stepValid: true })
        }
        data-cy="mfa_pwd_input"
      />
    </MfaWrapper>
  );
};

export const MfaTelValidate = props => {
  return (
    <MfaWrapper>
      <PhoneVertical style={{ width: '3rem', height: '3rem', fill: 'black' }} />
      <Heading>Enter 6-digit verification code</Heading>
      <p>
        The verification code was sent to your phone. Check your text messages
        and enter the code below.
      </p>
      <MfaValidateInput {...props} />
    </MfaWrapper>
  );
};

export const MfaPWDValidate = ({ password, email, onChange }) => {
  const [error, setError] = useState('unset');
  const verification = async (email, password) => {
    const { error } = await checkPassword(email, password);
    if (error) {
      setError(true);
      onChange({ stepValid: false });
    } else {
      setError(false);
      onChange({ stepValid: true });
    }
  };
  useEffect(() => {
    (async () => {
      await verification(email, password);
    })();
  }, []);

  return (
    <MfaWrapper>
      {error === 'unset' ? <p>Validating your password.</p> : null}
      {error === true ? (
        <p>There was an error with your password, please try again.</p>
      ) : null}
      {error === false ? (
        <p>Succesfully validated! Please continue to the next step.</p>
      ) : null}
    </MfaWrapper>
  );
};

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
}

/**
 *  validates the code received from twilio
 */
export const MfaValidateInput = ({
  onChange = () => {},
  onComplete,
  mFA,
  password,
  email,
  setup = true, // assume we're on the setup page, this component is also used on login
}) => {
  const [resend, setResend] = useState(true);
  const [seconds, setSeconds] = useState(1);
  const [error, setError] = useState(false);

  const resendCode = () => {
    setSeconds(60);
    setResend(false);
    sendVerifyCode(email, password, mFA);
  };
  const verification = async (code, mFA) => {
    const { error } = await verifyCode(code, mFA);
    if (error) {
      setError(true);
      onChange({ stepValid: false });
    } else {
      setError(false);
      onChange({ stepValid: true });
    }
  };
  useEffect(() => {
    let interval = null;

    if (!resend && seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (!resend) {
      clearInterval(interval);
      setResend(true);
    } else if (seconds > 0 && resend && setup) {
      sendVerifyCode(email, password, mFA);
      setSeconds(0);
    }
    return () => {
      clearInterval(interval);
    };
  }, [resend, seconds]);

  const border = error ? '2px solid red' : '2px solid #dedede';
  return (
    <Wrapper>
      <PinInput
        style={{ margin: '1rem 0' }}
        length={6}
        initialValue=""
        onChange={value => onChange({ verificationCode: value })}
        onComplete={value => {
          onComplete ? onComplete(value) : verification(value, mFA);
        }}
        type="numeric"
        inputStyle={{ width: '35px', border }}
      />
      {resend ? (
        <a onClick={resendCode}>{`Resend code to ${formatPhoneNumber(mFA)}`}</a>
      ) : (
        <p>{`Sent! You can try again in... ${seconds} seconds`}</p>
      )}
    </Wrapper>
  );
};
