import fetch from 'node-fetch';

export const sendVerifyCode = async (email, password, mFA) => {
  try {
    const response = await fetch(`/api/check_auth`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: email, password, mFA }),
    });
    return response.ok ? { success: true } : { error: 'invalid code' };
  } catch (e) {
    console.error(e);
  }
};

export const checkPassword = async (email, password) => {
  try {
    const response = await fetch(`/api/check_auth`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: email, password }),
    });
    return response.ok ? { success: true } : { error: 'invalid password' };
  } catch (e) {
    console.error(e);
    return { error: 'invalid password' };
  }
};

export const verifyCode = async (verificationCode, mFA) => {
  try {
    const response = await fetch('/api/verify', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mFA, verificationCode }),
    });
    return response.ok ? { success: true } : { error: 'invalid code' };
  } catch (e) {
    console.error(e);
  }
};
