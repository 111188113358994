import { StatusGood } from 'grommet-icons';
import styled from 'styled-components';
import { inputStyles } from 'atoms/ui/Input';

export const MfaWrapper = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > * {
    text-align: center;
    margin-bottom: 0.5rem;
  }
`;

export const Heading = styled.p`
  font-weight: bold;
  font-size: 1.4rem;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
`;

export const OK = styled(StatusGood)`
  stroke: ${props => props.theme.global.colors.brand.light};
  transition: 0.5s all ease-in-out;
  opacity: ${props => (props.valid ? 1 : 0)};
  transform: translateY(${props => (props.valid ? '0' : '100%')});
`;

export const InputWraper = styled.div`
  position: relative;
  z-index: 1;
`;

export const Input = styled.input`
  ${inputStyles}
  position: relative;
  z-index: 1;
  padding: 0.25rem;
  ${props => (props.icons ? 'padding-left: 2.3rem;' : '')}
  border: 1px solid #cccccc90;
`;

// Mfa Validate Input styles
export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 0 1rem;
  p {
    text-align: center;
  }
  .pincode-input-container {
    .pincode-input-text {
      width: 35px;
      border-radius: 10px;
      background: #ececec;
      color: ${props => props.theme.global.colors.brand.light};
    }
  }
`;
